import './publicpath';
import { createApp, defineAsyncComponent } from 'vue';
import { SwiperSlide } from 'swiper/vue/swiper-vue.js';
const app = createApp({
    components: { SwiperSlide }
});
app.component('BackButton', defineAsyncComponent(() => import('./components/atoms/BackButton.vue')));
app.component('BaseButton', defineAsyncComponent(() => import('./components/atoms/BaseButton.vue')));
app.component('BaseIcon', defineAsyncComponent(() => import('./components/atoms/BaseIcon.vue')));
app.component('CityItem', defineAsyncComponent(() => import('./components/atoms/CityItem.vue')));
app.component('ExpertiseItem', defineAsyncComponent(() => import('./components/atoms/ExpertiseItem.vue')));
app.component('GroupSwitchElement', defineAsyncComponent(() => import('./components/atoms/GroupSwitchElement.vue')));
app.component('Headline', defineAsyncComponent(() => import('./components/atoms/Headline.vue')));
app.component('IconText', defineAsyncComponent(() => import('./components/atoms/IconText.vue')));
app.component('ImageWithHeadline', defineAsyncComponent(() => import('./components/atoms/ImageWithHeadline.vue')));
app.component('ImageWithIcon', defineAsyncComponent(() => import('./components/atoms/ImageWithIcon.vue')));
app.component('ListItem', defineAsyncComponent(() => import('./components/atoms/ListItem.vue')));
app.component('Paragraph', defineAsyncComponent(() => import('./components/atoms/Paragraph.vue')));
app.component('ResponsiveImage', defineAsyncComponent(() => import('./components/atoms/ResponsiveImage.vue')));
app.component('SocialShareButton', defineAsyncComponent(() => import('./components/atoms/SocialShareButton.vue')));
app.component('TextComponent', defineAsyncComponent(() => import('./components/atoms/TextComponent.vue')));
app.component('VideoComponent', defineAsyncComponent(() => import('./components/atoms/VideoComponent.vue')));
app.component('AboutUs', defineAsyncComponent(() => import('./components/molecules/AboutUs.vue')));
app.component('AddressComponent', defineAsyncComponent(() => import('./components/molecules/AddressComponent.vue')));
app.component('AdvertisingTeaser', defineAsyncComponent(() => import('./components/molecules/AdvertisingTeaser.vue')));
app.component('CardComponent', defineAsyncComponent(() => import('./components/molecules/CardComponent.vue')));
app.component('GroupSwitch', defineAsyncComponent(() => import('./components/molecules/GroupSwitch.vue')));
app.component('HeadlineListLink', defineAsyncComponent(() => import('./components/molecules/HeadlineListLink.vue')));
app.component('JobCheckTeaserSquare', defineAsyncComponent(() => import('./components/molecules/JobCheckTeaserSquare.vue')));
app.component('JobTeaser', defineAsyncComponent(() => import('./components/molecules/JobTeaser.vue')));
app.component('LocationFilter', defineAsyncComponent(() => import('./components/molecules/LocationFilter.vue')));
app.component('MapCityContainer', defineAsyncComponent(() => import('./components/molecules/MapCityContainer.vue')));
app.component('PeopleTeaser', defineAsyncComponent(() => import('./components/molecules/PeopleTeaser.vue')));
app.component('QuoteComponent', defineAsyncComponent(() => import('./components/molecules/QuoteComponent.vue')));
app.component('StageCarousel', defineAsyncComponent(() => import('./components/molecules/StageCarousel.vue')));
app.component('TeaserItService', defineAsyncComponent(() => import('./components/molecules/TeaserItService.vue')));
app.component('TeaserXXL', defineAsyncComponent(() => import('./components/molecules/TeaserXXL.vue')));
app.component('VideoWithHeadline', defineAsyncComponent(() => import('./components/molecules/VideoWithHeadline.vue')));
app.component('Container', defineAsyncComponent(() => import('./components/layout/Container.vue')));
app.component('CustomerLogoRow', defineAsyncComponent(() => import('./components/layout/CustomerLogoRow.vue')));
app.component('FourColumn', defineAsyncComponent(() => import('./components/layout/FourColumn.vue')));
app.component('ImageRow', defineAsyncComponent(() => import('./components/layout/ImageRow.vue')));
app.component('OneColumn', defineAsyncComponent(() => import('./components/layout/OneColumn.vue')));
app.component('Page', defineAsyncComponent(() => import('./components/layout/Page.vue')));
app.component('RelativeContainer', defineAsyncComponent(() => import('./components/layout/RelativeContainer.vue')));
app.component('TeaserRow', defineAsyncComponent(() => import('./components/layout/TeaserRow.vue')));
app.component('ThreeColumn', defineAsyncComponent(() => import('./components/layout/ThreeColumn.vue')));
app.component('TopJobsColumnLayout', defineAsyncComponent(() => import('./components/layout/TopJobsColumnLayout.vue')));
app.component('TwoColumn', defineAsyncComponent(() => import('./components/layout/TwoColumn.vue')));
app.component('DirectSearchFooter', defineAsyncComponent(() => import('./components/organisms/DirectSearchFooter.vue')));
app.component('FlyIn', defineAsyncComponent(() => import('./components/organisms/FlyIn.vue')));
app.component('FooterComponent', defineAsyncComponent(() => import('./components/organisms/FooterComponent.vue')));
app.component('HeaderBar', defineAsyncComponent(() => import('./components/organisms/HeaderBar.vue')));
app.component('HeaderComponent', defineAsyncComponent(() => import('./components/organisms/HeaderComponent.vue')));
app.component('ImageText', defineAsyncComponent(() => import('./components/organisms/ImageText.vue')));
app.component('JobCheck', defineAsyncComponent(() => import('./components/organisms/JobCheck.vue')));
app.component('JobCheckWizard', defineAsyncComponent(() => import('./components/organisms/JobCheckWizard.vue')));
app.component('JobCheckValidator', defineAsyncComponent(() => import('./components/organisms/JobCheckValidator.vue')));
app.component('TopJobs', defineAsyncComponent(() => import('./components/organisms/TopJobs.vue')));
app.mount('#app');
